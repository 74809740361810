import { Redirect, Route, Switch } from 'react-router-dom';
import { Login, Success, Totp } from './pages';

function App() {
  return (
    <Switch>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/totp" exact>
        <Totp />
      </Route>
      <Route path="/success" exact>
        <Success />
      </Route>
      <Redirect from="/" to="/login" />
    </Switch>
  );
}

export default App;
