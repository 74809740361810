import { Box } from '@mui/system';
import svg from './logo.svg';
import React from 'react';

export const Logo = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <img height="50" src={svg} alt="logo" />
    </Box>
  );
};
