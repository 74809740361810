import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const Success = () => {
  const location = useLocation();

  useEffect(() => {
    const redirect = queryString.parse(location.search).return as string;

    window.location.href = redirect ?? '/app/';
  }, [location.search]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        maxWidth: 500,
        margin: 'auto'
      }}
    >
      <Typography variant="title">You have successfully logged in</Typography>
    </Box>
  );
};
