import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true
});

class ApiService {
  async login(data: { email: string; password: string }) {
    const formBody = [];
    for (const property in data) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent((data as any)[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    const body = formBody.join('&');
    try {
      await instance.post('api/auth/sign-in/', body, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' }
      });

      return '';
    } catch (e) {
      console.log(e);
    }

    return 'wrong login or password';
  }

  async verifyTotp(token: string) {
    try {
      const body = `token=${encodeURIComponent(token)}`;
      const { data } = await instance.post<{ valid: boolean }>('api/auth/verify-totp-token', body, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' }
      });

      return data;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  async getProfile() {
    try {
      const response = await instance.get<{
        email: string;
        first_name: string;
        last_name: string;
        success: true;
      }>('api/auth/profile');

      return response.data;
    } catch (e) {
      console.log(e);
    }

    return { success: false } as const;
  }

  async getTotpSecret(): Promise<string> {
    try {
      const response = await instance.get('api/auth/generate-totp-secret');
      return response.data.secret;
    } catch (e) {
      console.log(e);
      return '';
    }
  }
}

export const apiService = new ApiService();
