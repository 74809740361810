/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import { authenticator } from '@otplib/preset-default';
import qrcode from 'qrcode';
import { apiService } from '../../api';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { FieldText } from '../../components/FieldText/FieldText';
import { useHistory, useLocation } from 'react-router-dom';

type ProfileType = ReturnType<typeof apiService.getProfile>;
type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export const Totp = () => {
  const [url, setUrl] = useState('');
  const [profile, setProfile] = useState<Awaited<ProfileType>>({ success: false });
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const onSubmit = async ({ code }: { code: string }) => {
    const result = await apiService.verifyTotp(code);

    if (!result) {
      history.push(`/login${location.search}`);

      return '';
    }

    if (typeof result === 'object' && result.valid) {
      history.push(`/success${location.search}`);

      return '';
    }

    return 'invalid code';
  };

  useEffect(() => {
    if (profile.success) {
      return;
    }
    apiService.getProfile().then((result) => {
      setProfile(result);
      if (!result.success) {
        history.push(`/${location.search}`);
      }
    });
  }, [history, location.search, profile.success]);

  useEffect(() => {
    if (!profile.success) {
      return;
    }

    apiService.getTotpSecret().then((secret) => {
      setLoading(false);
      if (!secret) {
        return;
      }

      const otpauth = authenticator.keyuri(profile.email, 'Desgin Huddle', secret);
      qrcode.toDataURL(otpauth, (err, imageUrl) => {
        setUrl(imageUrl);
      });
    });
  }, [profile]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        maxWidth: 500,
        margin: 'auto'
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ width: '100%', px: 1 }}>
          {url ? (
            <>
              <Typography variant="title">Authenticator Passcode</Typography>
              <Typography variant="subtitle1">
                Please scan the QR code below and enter the code below.
              </Typography>
              <img style={{ display: 'block', margin: 'auto' }} src={url} alt="qr-code" />
            </>
          ) : (
            <>
              <Typography variant="title">Authenticator Passcode Required</Typography>
              <Typography variant="subtitle1">
                You'll need the six-digit code from your authenticator app to continue.
              </Typography>
            </>
          )}
          <Form<{ code: string }>
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitting,
              submitFailed,
              dirtySinceLastSubmit,
              submitErrors
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
              >
                <FieldText name="code" label="Verification Code:" focused />
                <Box sx={{ height: 24, color: '#ff1744', textAlign: 'center' }}>
                  {dirtySinceLastSubmit ? '' : submitErrors}
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={(submitFailed && !dirtySinceLastSubmit) || submitting}
                  sx={{ mt: 1, minWidth: 200, mx: 'auto' }}
                >
                  Sign In
                </Button>
              </form>
            )}
          />
        </Box>
      )}
    </Box>
  );
};
