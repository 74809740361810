import { OutlinedInputProps, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';
import { Field } from 'react-final-form';

const CustomTextField = styled((props: TextFieldProps) => (
  <TextField InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>} {...props} />
))(({ theme }: any) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #dfe1e4',
    overflow: 'hidden',
    borderRadius: 4,
    color: '#000000 !important',
    fontWeight: '600 !important',
    fontSize: 16,
    boxShadow: '0 0 0px 1000px white inset',
    backgroundColor: '#ffffff !important',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#ffffff !important'
    },
    '&.Mui-focused': {
      backgroundColor: '#ffffff !important',
      borderColor: theme.palette.primary.main
    }
  },
  '& .MuiInputLabel-root': {
    color: '#bbbbbc',
    fontWeight: '400',
    fontSize: 11
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    bottom: -20
  }
}));

export const FieldText: FC<{
  name: string;
  label: string;
  type?: string;
  focused?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
}> = ({ name, label, type = 'text', variant = 'filled', focused = false }) => {
  return (
    <Field name={name} type={type} validate={required}>
      {({ input, meta }) => (
        <CustomTextField
          {...input}
          autoFocus={focused}
          fullWidth
          error={meta.error && meta.touched}
          sx={{ my: 2 }}
          label={label}
          variant={variant}
          helperText={meta.touched ? meta.error : ''}
        />
      )}
    </Field>
  );
};
const required = (value: string) => (value ? undefined : 'Required');
