/* eslint-disable @typescript-eslint/naming-convention */
import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: '#7c60ff'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    }
  },
  typography: {
    fontFamily: '"Open Sans",sans-serif',
    fontSize: 12,
    title: {
      fontWeight: 700,
      fontSize: 20,
      textAlign: 'center',
      color: '#050505',
      display: 'block'
    },
    subtitle1: {
      fontSize: 12,
      textAlign: 'center',
      color: '#A6A6A8'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          whiteSpace: 'nowrap',
          boxShadow: 'none',
          textTransform: 'none',
          fontWeight: 600,
          fontSize: 12,
          color: 'white',
          height: 37,
          width: 197
        }
      }
    }
  }
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: TypographyStyleOptions;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
  }
}
