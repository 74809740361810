import { Button, Checkbox, Box, FormControlLabel } from '@mui/material';
import React from 'react';
import { Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import { apiService } from '../../api';
import { Logo } from '../../components';
import { FieldText } from '../../components/FieldText/FieldText';

export const Login = () => {
  const history = useHistory();
  const location = useLocation();

  const onSubmit = async (data: { email: string; password: string }) => {
    const result = await apiService.login(data);
    if (result) {
      return result;
    }
    history.push(`/totp${location.search}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        maxWidth: 500,
        margin: 'auto'
      }}
    >
      <Box sx={{ width: '100%', px: 1 }}>
        <Logo />
        <Form<{ email: string; password: string }>
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            submitting,
            submitErrors,
            submitFailed,
            dirtySinceLastSubmit
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: 30
              }}
            >
              <FieldText name="email" label="Email:" focused />
              <FieldText name="password" label="Password:" type="password" />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  control={<Checkbox onChange={(v) => console.log(v.target.checked)} />}
                  label="Remember me"
                />
              </Box>
              <Box sx={{ height: 24, color: '#ff1744', textAlign: 'center' }}>
                {dirtySinceLastSubmit ? '' : submitErrors}
              </Box>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={(submitFailed && !dirtySinceLastSubmit) || submitting}
                sx={{ mt: 1, minWidth: 200, mx: 'auto' }}
              >
                Sign In
              </Button>
            </form>
          )}
        />
      </Box>
    </Box>
  );
};
